import image0Url from '@assets/lottie/pause/image_0.png';
import { loadImage } from '@helpers/loadImage';

export default new Promise((resolve) => {
  Promise.all([loadImage(image0Url)]).finally(() =>
    resolve({
      v: '5.9.4',
      fr: 25,
      ip: 0,
      op: 49,
      w: 600,
      h: 600,
      ddd: 0,
      assets: [
        {
          id: 'image_0',
          w: 384,
          h: 384,
          u: '',
          p: image0Url,
          e: 1
        }
      ],
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          parent: 3,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 3,
                  s: [0]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 7,
                  s: [10]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 32,
                  s: [10]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 48,
                  s: [0]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 235,
                  s: [0]
                },
                { t: 251, s: [10] }
              ],
              ix: 11
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [192.731, 191.731, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-737.269, -113.269, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 3.25,
                  s: [116, 116, 100]
                },
                { t: 7, s: [100, 100, 100] }
              ],
              ix: 6,
              l: 2,
              x: "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
            }
          },
          ao: 0,
          ef: [
            {
              ty: 5,
              np: 5,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  ix: 1,
                  v: { a: 0, k: 20, ix: 1 }
                },
                {
                  ty: 0,
                  ix: 2,
                  v: { a: 0, k: 40, ix: 2 }
                },
                {
                  ty: 0,
                  ix: 3,
                  v: { a: 0, k: 60, ix: 3 }
                }
              ]
            }
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [377.461, 377.461], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 6, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-737.269, -113.269], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 49,
          st: -19,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          parent: 3,
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 3,
                  s: [0]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 6.601,
                  s: [30]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 32,
                  s: [30]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 48,
                  s: [0]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 235,
                  s: [0]
                },
                { t: 251, s: [30] }
              ],
              ix: 11
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [192.731, 191.731, 0], ix: 2, l: 2 },
            a: { a: 0, k: [-737.269, -113.269, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 3,
                  s: [116, 116, 100]
                },
                { t: 6.75, s: [100, 100, 100] }
              ],
              ix: 6,
              l: 2,
              x: "var $bm_rt;\nvar amp, freq, decay, n, n, t, t, v;\ntry {\n    amp = $bm_div(effect('Elastic: Scale')(1), 200);\n    freq = $bm_div(effect('Elastic: Scale')(2), 30);\n    decay = $bm_div(effect('Elastic: Scale')(3), 10);\n    $bm_rt = n = 0;\n    if (numKeys > 0) {\n        $bm_rt = n = nearestKey(time).index;\n        if (key(n).time > time) {\n            n--;\n        }\n    }\n    if (n == 0) {\n        $bm_rt = t = 0;\n    } else {\n        $bm_rt = t = $bm_sub(time, key(n).time);\n    }\n    if (n > 0) {\n        v = velocityAtTime($bm_sub(key(n).time, $bm_div(thisComp.frameDuration, 10)));\n        $bm_rt = $bm_sum(value, $bm_div($bm_mul($bm_mul(v, amp), Math.sin($bm_mul($bm_mul($bm_mul(freq, t), 2), Math.PI))), Math.exp($bm_mul(decay, t))));\n    } else {\n        $bm_rt = value;\n    }\n} catch (e$$4) {\n    $bm_rt = value = value;\n}"
            }
          },
          ao: 0,
          ef: [
            {
              ty: 5,
              np: 5,
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 0,
                  ix: 1,
                  v: { a: 0, k: 20, ix: 1 }
                },
                {
                  ty: 0,
                  ix: 2,
                  v: { a: 0, k: 40, ix: 2 }
                },
                {
                  ty: 0,
                  ix: 3,
                  v: { a: 0, k: 60, ix: 3 }
                }
              ]
            }
          ],
          shapes: [
            {
              ty: 'gr',
              it: [
                {
                  d: 1,
                  ty: 'el',
                  s: { a: 0, k: [311.461, 311.461], ix: 2 },
                  p: { a: 0, k: [0, 0], ix: 3 },
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 6, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [-737.269, -113.269], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 }
                }
              ],
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              hd: false
            }
          ],
          ip: 0,
          op: 49,
          st: -19,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 2,
          cl: 'png',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 4.05,
                  s: [0]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 5.25,
                  s: [100]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 32,
                  s: [100]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 48,
                  s: [0]
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  t: 235,
                  s: [0]
                },
                { t: 251, s: [100] }
              ],
              ix: 11
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [300, 300, 0], ix: 2, l: 2 },
            a: { a: 0, k: [193.999, 187.47, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0, 0, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 3,
                  s: [130, 130, 100]
                },
                { t: 6.75, s: [100, 100, 100] }
              ],
              ix: 6,
              l: 2
            }
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: 'a',
              pt: {
                a: 0,
                k: {
                  i: [
                    [76.215, -0.019],
                    [-0.019, -75.923],
                    [-76.215, 0.019],
                    [0.019, 75.923]
                  ],
                  o: [
                    [-76.215, 0.019],
                    [0.019, 75.924],
                    [76.215, -0.019],
                    [-0.019, -75.923]
                  ],
                  v: [
                    [193.965, 49.999],
                    [56, 187.505],
                    [194.034, 324.942],
                    [331.999, 187.436]
                  ],
                  c: true
                },
                ix: 1
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 }
            }
          ],
          ip: 0,
          op: 49,
          st: -19,
          bm: 0
        }
      ],
      markers: []
    })
  );
});
